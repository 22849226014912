// @ts-nocheck
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  TimePicker,
  TreeSelect,
  Upload,
} from 'antd'
import {
  FormButtonGroup,
  InfoBlock,
} from './CompanyWorkScheduleCreatePageStyled'
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { toJS } from 'mobx'
import { Container } from '../../layouts/Container/Container'
import { EmptyText, WordBreak } from '../../components/Common/CellText'
import RichEditor from '../../components/RichEditor/RichEditor'
import { blue } from '../../color'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_LIST,
  HH_mm,
  HHhMM,
  ISO_DATE_FORMAT,
  TIME_FORMAT_LIST,
} from '../../constants'
import UploadFileItem from '../../components/Common/FileItemComponent/UploadFileItem'
import AccessControlAction from '../../components/AccessControlAction'
import RRule from './RRule'
import CompanyWorkScheduleConfirmSendNotificaitonModal from './CompanyWorkScheduleConfirmSendNotificationModal'
import validator from './../../validator'
import { systemName } from '../../config'

const { SHOW_CHILD } = TreeSelect
let submitValues

const CompanyWorkScheduleCreatePage = props => {
  const {
    fileStore,
    loadingAnimationStore,
    workScheduleStore,
    history,
    match,
    authenticationStore,
    departmentStore,
    aclStore,
  } = props
  const { workScheduleDetail } = workScheduleStore
  const { currentUser } = authenticationStore
  const { departmentList } = departmentStore
  const { aclAclGroupCode } = aclStore

  const {
    title,
    start_at,
    end_at,
    preparation,
    host,
    location,
    event_notice,
    attenders,
    assignees,
    created_at,
    last_edit_by,
    updated_at,
  } = workScheduleDetail

  const { scheduleId } = match.params
  const [form] = Form.useForm()
  const EDITOR_REF = useRef()
  const [
    isCofirmSendNotificationModalVisible,
    setConfirmSendNotificationModalVisibility,
  ] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const confirmSendNotificationModal = Object.freeze({
    open: () => setConfirmSendNotificationModalVisibility(true),
    close: () => setConfirmSendNotificationModalVisibility(false),
  })

  const confirmUpdate = async ({ is_notification_required }) => {
    setIsLoading(true)
    try {
      await handleUpdateWorkSchedule({
        ...submitValues,
        is_notification_required,
      })
      confirmSendNotificationModal.close()
    } catch (error) {
      console.error('error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const filterCurrentUserDepartmentList = toJS(departmentList).filter(
    el => el.value !== currentUser?.username
  )

  const showPersonAndDate = (person, date) => {
    if (person) {
      return (
        <div>
          {`${utils.getNameInCapitalize(person)} - `}
          <span>{moment(date).format(DATE_FORMAT_DEFAULT)}</span>&nbsp;
          <span>{moment(date).format(HHhMM)}</span>
        </div>
      )
    }
    return <EmptyText>Chưa có thông tin</EmptyText>
  }

  const [fileList, setFileList] = useState([])
  const [editMode, setEditMode] = useState(true)
  const [checkEditSchedule, setCheckEditSchedule] = useState(false)

  const recipientNoticeList =
    assignees &&
    toJS(assignees).filter(el => el.permission === ASSIGNEE_TYPE.FOLLOWER)

  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])

  const handleCreateWorkSchedule = async submitData => {
    const START_TIME = moment(submitData.start_time).format(HHhMM)
    const END_TIME = moment(submitData.end_time).format(HHhMM)
    const DATE_WORK = moment(submitData.start_at).format(DATE_FORMAT_DEFAULT)
    try {
      await workScheduleStore.createWorkSchedule(submitData)
      history.push('/company-work-schedule')
      message.success({
        content: submitData.end_time ? (
          <span>
            Tạo công việc ngày&nbsp;<b>{DATE_WORK}&nbsp;</b>
            thời gian từ&nbsp;<b>{START_TIME}&nbsp;</b>đến&nbsp;
            <b>{END_TIME}&nbsp;</b>thành công!
          </span>
        ) : (
          <span>
            Tạo công việc ngày&nbsp;<b>{DATE_WORK}&nbsp;</b>
            thời gian từ&nbsp;<b>{START_TIME}&nbsp;</b>thành công!
          </span>
        ),
      })
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra!')
    }
  }
  const handleUpdateWorkSchedule = async submitData => {
    const START_TIME = moment(submitData.start_time).format(HHhMM)
    const END_TIME = moment(submitData.end_time).format(HHhMM)
    const DATE_WORK = moment(submitData.start_at).format(DATE_FORMAT_DEFAULT)
    try {
      await workScheduleStore.updateWorkSchedule(scheduleId, submitData)
      history.push('/company-work-schedule')
      message.success({
        content: submitData.end_time ? (
          <span>
            Cập nhật công việc ngày&nbsp;<b>{DATE_WORK}&nbsp;</b>thời gian
            từ&nbsp;
            <b>{START_TIME}&nbsp;</b>đến&nbsp;<b>{END_TIME}&nbsp;</b>thành công!
          </span>
        ) : (
          <span>
            Cập nhật công việc ngày&nbsp;<b>{DATE_WORK}&nbsp;</b>bắt đầu
            từ&nbsp;
            <b>{START_TIME}&nbsp;</b>thành công!
          </span>
        ),
      })
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra')
    }
  }

  const handleSubmitForm = useCallback(
    async values => {
      const START_AT = `${moment(values.start_date).format(
        ISO_DATE_FORMAT
      )} ${moment(values.start_time).format(HH_mm)}`
      const END_AT = values.end_time
        ? `${moment(values.start_date).format(ISO_DATE_FORMAT)} ${moment(
            values.end_time
          ).format(HH_mm)}`
        : null
      let arrRecipientNotice = []
      if (!checkEditSchedule) {
        arrRecipientNotice = values.recipient_notice
          ?.filter(el => !utils.isUpper(el))
          .map(code => ({
            assignee_code: code,
            assignee_type: ASSIGNEE_TYPE.USER,
            permission: ASSIGNEE_TYPE.FOLLOWER,
          }))
      } else {
        const recipient_notice = recipientNoticeList
          .filter(item => values.recipient_notice.includes(item.name_uppercase))
          .map(filteredItem => filteredItem.assignee_code)
        const result = [...values.recipient_notice, ...recipient_notice]
        arrRecipientNotice = result
          ?.filter(el => !utils.isUpper(el))
          .map(code => ({
            assignee_code: code,
            assignee_type: ASSIGNEE_TYPE.USER,
            permission: ASSIGNEE_TYPE.FOLLOWER,
          }))
      }

      const new_items = []
      const remove_items = []

      if (scheduleId) {
        const assignPersonUpdate = [...recipientNoticeList]
        arrRecipientNotice.forEach(user => {
          if (
            !assignPersonUpdate
              .map(el => el.assignee_code + el.permission)
              .includes(user.assignee_code + user.permission)
          ) {
            // push new_items
            new_items.push({
              assignee_code: user.assignee_code,
              assignee_type: user.assignee_type,
              permission: user.permission,
            })
          }
        })

        remove_items.push(
          ...assignPersonUpdate
            .filter(
              user =>
                !arrRecipientNotice.find(
                  el =>
                    el.assignee_code + el.permission ===
                    user.assignee_code + user.permission
                )
            )
            .map(user => ({
              assignee_code: user.assignee_code,
              assignee_type: user.assignee_type,
              permission: user.permission,
            }))
        )
      }

      let FILE_IDS = []
      if (scheduleId) {
        if (workScheduleDetail.file_ids.length) {
          FILE_IDS = workScheduleDetail.file_ids.map(file => file.file_id)
        }
      }

      submitValues = {
        attenders: values.attenders,
        end_time: values.end_time,
        host: values.host.replace(/\s+/g, ' ').trim(),
        location: values.location.replace(/\s+/g, ' ').trim(),
        preparation: values.preparation,
        start_date: values.start_date,
        start_time: values.start_time,
        title: '',
        start_at: moment(START_AT).toISOString(),
        end_at: END_AT ? moment(END_AT).toISOString() : null,
        file_ids: FILE_IDS,
        assignees: arrRecipientNotice,
        rrule: RRule.extractFromFormValues(values),
      }

      if (scheduleId) {
        submitValues = {
          ...submitValues,
          assignees: arrRecipientNotice,
          assign_person_update: {
            new_items: new_items.filter(
              el => el.assignee_code !== currentUser?.username
            ),
            remove_items: remove_items.filter(
              el => el.assignee_code !== currentUser?.username
            ),
          },
        }
      }

      submitValues.event_notice = EDITOR_REF.current.editor.getData()

      const uploadFileList = fileList.map(file => file.originFileObj)
      const batchUploadArr = []
      uploadFileList.forEach(file => {
        const formData = new FormData()
        formData.append('file', file)
        batchUploadArr.push(fileStore.uploadFile(formData))
      })
      try {
        loadingAnimationStore.showSpinner(true)
        const response = await Promise.all(batchUploadArr)
        if (scheduleId) {
          submitValues.file_ids = [
            ...submitValues.file_ids,
            ...response.map(response => response.data.file_id),
          ]

          confirmSendNotificationModal.open()
          return
        }
        submitValues.file_ids = response.map(response => response.data.file_id)
        await handleCreateWorkSchedule(submitValues)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    },
    [scheduleId, workScheduleDetail.file_ids, fileList, checkEditSchedule]
  )

  const handleRemoveAttachment = useCallback(
    fileId => {
      const newScheduleDetail = {
        ...workScheduleDetail,
        file_ids: toJS(workScheduleDetail.file_ids).filter(
          item => item.file_id !== fileId
        ),
      }
      workScheduleStore.updateWorkScheduleDetail(newScheduleDetail)
    },
    [workScheduleDetail]
  )

  const deleteScheduled = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await workScheduleStore.delete(scheduleId)
      history.push('/company-work-schedule')
    } catch (e) {
      message.error(e.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const renderFormContent = () => (
    <Form
      form={form}
      scrollToFirstError={true}
      layout={'vertical'}
      name={'add-agenda-form'}
      style={{ paddingTop: '1rem' }}
      onFinish={handleSubmitForm}
      initialValues={{
        start_date: moment(),
      }}>
      {/*<Form.Item*/}
      {/*  name={'title'}*/}
      {/*  label={'Tiêu đề'}*/}
      {/*  rules={[*/}
      {/*    { required: true, message: 'Vui lòng nhập tiêu đề sự kiện!' },*/}
      {/*    { max: 500, message: 'Tiêu đề không được quá 500 ký tự' },*/}
      {/*  ]}>*/}
      {/*<Input placeholder={'Nhập tiêu đề sự kiện'} />*/}
      {/*</Form.Item>*/}
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item
            name={'start_date'}
            label={'Ngày thực hiện'}
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày bắt đầu sự kiện!',
              },
            ]}>
            <DatePicker
              format={DATE_FORMAT_LIST}
              style={{ width: '100%' }}
              placeholder={'Chọn ngày thực hiện'}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'start_time'}
            label={'Thời gian bắt đầu'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập thời gian bắt đầu diễn ra sự kiện!',
              },
            ]}>
            <TimePicker
              format={TIME_FORMAT_LIST}
              minuteStep={5}
              style={{ width: '100%' }}
              placeholder={'Bắt đầu'}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={'end_time'} label={'Thời gian kết thúc'}>
            <TimePicker
              format={TIME_FORMAT_LIST}
              minuteStep={5}
              style={{ width: '100%' }}
              placeholder={'Kết thúc'}
            />
          </Form.Item>
        </Col>
      </Row>

      {!scheduleId ? <RecurrenceOption /> : null}

      <Form.Item
        label={'Chủ trì'}
        name={'host'}
        rules={[
          { required: true, message: 'Vui lòng nhập tên chủ trì sự kiện!' },
          { max: 2000, message: 'Chủ trì không được quá 2000 ký tự' },
          { validator: validator.validateInputString },
        ]}>
        <Input placeholder={'Chủ trì'} />
      </Form.Item>
      <Form.Item
        label={'Địa điểm'}
        name={'location'}
        rules={[
          { required: true, message: 'Vui lòng nhập địa điểm!' },
          { max: 2000, message: 'Địa điểm không được quá 2000 ký tự' },
          { validator: validator.validateInputString },
        ]}>
        <Input placeholder={'Địa điểm'} />
      </Form.Item>
      <Form.Item
        name={'preparation'}
        label={'Chuẩn bị'}
        rules={[{ max: 2000, message: 'Chuẩn bị không được quá 2000 ký tự' }]}>
        <Input placeholder={'Chuẩn bị'} />
      </Form.Item>
      <Form.Item label={'Nội dung sự kiện'} name={'event_notice'}>
        <RichEditor
          EDITOR_REF={EDITOR_REF}
          placeholder={'Nhập nội dung sự kiện'}
          editorContent={event_notice}
        />
      </Form.Item>
      <Form.Item name={'file_ids'} label={'Tài liệu đính kèm'}>
        <Upload
          multiple={true}
          valuePropName={'fileList'}
          fileList={fileList}
          onChange={handleChangeFile}
          beforeUpload={() => false}
          iconRender={() => <FileTextOutlined />}>
          <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
        </Upload>
      </Form.Item>
      {workScheduleDetail.file_ids && workScheduleDetail.file_ids.length !== 0 && (
        <Form.Item label={'Cập nhật tài liệu đính kèm'}>
          {workScheduleDetail.file_ids.map(file => (
            <UploadFileItem
              key={file.file_id}
              file_id={file.file_id}
              file_name={file.file_title}
              file_type={utils.getExtensionFile(file.file_title)}
              isDownloadFile={true}
              handleRemoveFileFromUploadList={() =>
                handleRemoveAttachment(file.file_id)
              }
            />
          ))}
        </Form.Item>
      )}

      <Form.Item label="Thành viên tham gia" name={'attenders'}>
        <Input placeholder={'Thành viên tham gia'} />
      </Form.Item>
      <Form.Item label="Thông báo" name={'recipient_notice'}>
        <TreeSelect
          treeDataSimpleMode
          treeData={filterCurrentUserDepartmentList}
          style={{ width: '100%' }}
          showArrow
          allowClear
          showSearch
          filterTreeNode
          treeNodeFilterProp={'name'}
          treeCheckable={true}
          showCheckedStrategy={SHOW_CHILD}
          placeholder={'Chọn người nhận thông báo'}
        />
      </Form.Item>

      <FormButtonGroup>
        <Button htmlType={'submit'} style={{ marginLeft: 10 }} type={'primary'}>
          {scheduleId ? 'Cập nhật sự kiện' : 'Tạo mới sự kiện'}
        </Button>
      </FormButtonGroup>
    </Form>
  )

  const renderViewContent = () => (
    <>
      <InfoBlock>
        <dt>Thông tin</dt>
        <dd>Mô tả chi tiết</dd>
        {/*<dt>Tiêu đề</dt>*/}
        {/*<dd>{title}</dd>*/}
        <dt>Ngày thực hiện</dt>
        <dd>
          {start_at ? (
            <>
              <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
              <span>{moment(start_at).format(DATE_FORMAT_DEFAULT)}</span>
            </>
          ) : (
            <EmptyText>Không rõ.</EmptyText>
          )}
        </dd>
        <dt>Thời gian bắt đầu</dt>
        <dd>
          {start_at ? (
            <>
              <FieldTimeOutlined style={{ color: blue, marginRight: 4 }} />
              <span>{moment(start_at).format(HHhMM)}</span>
            </>
          ) : (
            <EmptyText>Không rõ.</EmptyText>
          )}
        </dd>
        <dt>Thời gian kết thúc</dt>
        <dd>
          {end_at ? (
            <>
              <FieldTimeOutlined style={{ color: blue, marginRight: 4 }} />
              <span>{moment(end_at).format(HHhMM)}</span>
            </>
          ) : (
            <EmptyText>Không rõ.</EmptyText>
          )}
        </dd>
        <dt>Chủ trì</dt>
        <dd>{host}</dd>
        <dt>Địa điểm</dt>
        <dd>{location && WordBreak(utils.urlify(location), true)}</dd>
        <dt>Chuẩn bị</dt>
        <dd>
          {preparation ? (
            WordBreak(utils.urlify(preparation), true)
          ) : (
            <EmptyText>Không có chuẩn bị.</EmptyText>
          )}
        </dd>
        <dt>Nội dung sự kiện</dt>
        <dd>
          {event_notice ? (
            WordBreak(event_notice, true)
          ) : (
            <EmptyText>Không có nội dung sự kiện.</EmptyText>
          )}
        </dd>
        <dt>Tài liệu đính kèm</dt>
        <dd>
          {workScheduleDetail.file_ids?.length ? (
            workScheduleDetail.file_ids.map(file => (
              <FileItem
                key={file.file_id}
                file_id={file.file_id}
                file_name={file.file_title}
                file_type={utils.getExtensionFile(file.file_title)}
              />
            ))
          ) : (
            <EmptyText>Không có tài liệu đính kèm.</EmptyText>
          )}
        </dd>
        <dt>Thành viên tham gia</dt>
        <dd>
          {attenders ? (
            attenders
          ) : (
            <EmptyText>Không có thành viên tham gia.</EmptyText>
          )}
        </dd>
        <dt>Thông báo</dt>
        <dd>
          {recipientNoticeList?.length > 0 ? (
            <span>
              {recipientNoticeList
                ?.map(el => utils.getNameInCapitalize(el.name_uppercase))
                .join(', ')}
            </span>
          ) : (
            <EmptyText>Không có người nhận thông báo.</EmptyText>
          )}
        </dd>
        <dt>Ngày tạo</dt>
        <dd>
          {showPersonAndDate(
            assignees?.find(user => user.permission === ASSIGNEE_TYPE.CREATE)
              ?.name_uppercase,
            created_at
          )}
        </dd>
        <dt>Chỉnh sửa lần cuối</dt>
        <dd>{showPersonAndDate(last_edit_by?.name_lowercase, updated_at)}</dd>
      </InfoBlock>
      <FormButtonGroup>
        <AccessControlAction
          aclActionType={ACL_ACTION_TYPE.delete__WORK_SCHEDULE}>
          <Popconfirm
            placement="top"
            title={'Bạn có chắc chắn muốn xóa lịch này không ?'}
            onConfirm={() => deleteScheduled()}
            okText="Đồng ý"
            cancelText="Không">
            <Button
              icon={<DeleteOutlined />}
              // @ts-ignore
              type={'danger'}>
              Xóa
            </Button>
          </Popconfirm>
        </AccessControlAction>
        {aclAclGroupCode === 'default_admin' ||
        currentUser?.is_admin === true ? (
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.edit__WORK_SCHEDULE}>
            <Button
              icon={<EditOutlined />}
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={() => {
                setEditMode(true)
                setCheckEditSchedule(true)
              }}>
              Chỉnh sửa
            </Button>
          </AccessControlAction>
        ) : currentUser?.username ===
          assignees?.filter(el => el.permission === 'CREATE')[0]
            .assignee_code ? (
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.edit__WORK_SCHEDULE}>
            <Button
              icon={<EditOutlined />}
              style={{ marginLeft: 10 }}
              type={'primary'}
              onClick={() => {
                setEditMode(true)
                setCheckEditSchedule(true)
              }}>
              Chỉnh sửa
            </Button>
          </AccessControlAction>
        ) : null}
      </FormButtonGroup>
    </>
  )

  useEffect(() => {
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await departmentStore.getDepartmentWithUsers(
          authenticationStore.currentCompanyCode()
        )
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (scheduleId) {
      ;(async () => {
        try {
          loadingAnimationStore.showSpinner(true)
          setEditMode(false)
          await workScheduleStore.getWorkScheduleDetail(scheduleId)
        } catch (err) {
          history.push('/company-work-schedule')
          console.log(err)
          message.error(err?.vi || 'Đã có lỗi xảy ra!')
        } finally {
          loadingAnimationStore.showSpinner(false)
        }
      })()
    }
    return () => {
      workScheduleStore.clearWorkScheduleDetail()
    }
  }, [scheduleId])
  useEffect(() => {
    scheduleId &&
      form.setFieldsValue({
        title: title,
        start_date: moment(start_at),
        start_time: moment(start_at),
        end_time: end_at && moment(end_at),
        host: host,
        location: location,
        event_notice: event_notice,
        attenders: attenders,
        preparation: preparation,
        recipient_notice: recipientNoticeList?.map(user => user.assignee_code),
      })
  }, [scheduleId, workScheduleDetail])

  return (
    <DashboardLayout title={'Link People - Link the World'}>
      <CompanyWorkScheduleConfirmSendNotificaitonModal
        visible={isCofirmSendNotificationModalVisible}
        onCancel={confirmSendNotificationModal.close}
        onConfirm={confirmUpdate}
        isLoading={isLoading}
      />
      <Helmet>
        <title>
          {scheduleId
            ? checkEditSchedule
              ? 'Chỉnh sửa sự kiện'
              : 'Chi tiết sự kiện'
            : 'Tạo mới sự kiện'}{' '}
          | {systemName} Portal
        </title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={
          scheduleId
            ? checkEditSchedule
              ? 'Chỉnh sửa sự kiện'
              : 'Chi tiết sự kiện'
            : 'Tạo mới sự kiện'
        }
        showTitle={true}
      />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          {editMode ? renderFormContent() : renderViewContent()}
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}
const RecurrenceOption = () => {
  const [isRecurrence, setRecurrenceStatus] = useState(false)
  const [frequenceType, setFrequenceType] = useState(RRule.FrequenceType.WEEKLY)
  const occurenceCountMax = {
    [RRule.FrequenceType.WEEKLY]: 4,
    [RRule.FrequenceType.MONTHLY]: 3,
  }
  const recurrenceText = {
    [RRule.FrequenceType.WEEKLY]: 'tuần xuất hiện',
    [RRule.FrequenceType.MONTHLY]: 'tháng xuất hiện',
  }

  const onToggleRecurrence = value => {
    setRecurrenceStatus(!!value)
  }

  const onChangeFrequenceType = value => {
    setFrequenceType(RRule.FrequenceType[value])
  }

  const frequenceTypeOptions = [
    {
      label: 'tuần',
      value: RRule.FrequenceType.WEEKLY,
    },
    {
      label: 'tháng',
      value: RRule.FrequenceType.MONTHLY,
    },
  ]

  const FrequenceTypeSelect = () => (
    <Form.Item
      name={'repeat_type'}
      style={{ marginBottom: 0, height: 30 }}
      initialValue={RRule.FrequenceType.WEEKLY}>
      <Select
        value={frequenceType}
        onChange={onChangeFrequenceType}
        style={{ width: 80 }}
        options={frequenceTypeOptions}
      />
    </Form.Item>
  )

  const DayOfWeekCheckboxGroup = () => (
    <Row>
      <Form.Item
        name={'day_of_weeks'}
        label={'Lặp lại vào'}
        rules={[
          { required: true, message: 'Bạn chưa chọn ngày trong tuần nào' },
        ]}>
        <Checkbox.Group>
          <Space size={'middle'}>
            <Checkbox value={RRule.DayOfWeek.MONDAY}>T2</Checkbox>
            <Checkbox value={RRule.DayOfWeek.TUESDAY}>T3</Checkbox>
            <Checkbox value={RRule.DayOfWeek.WEDNESDAY}>T4</Checkbox>
            <Checkbox value={RRule.DayOfWeek.THURSDAY}>T5</Checkbox>
            <Checkbox value={RRule.DayOfWeek.FRIDAY}>T6</Checkbox>
            <Checkbox value={RRule.DayOfWeek.SATURDAY}>T7</Checkbox>
            <Checkbox value={RRule.DayOfWeek.SUNDAY}>CN</Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </Row>
  )

  return (
    <>
      <Row>
        <Space>
          <Form.Item name={'is_repeat'}>
            <Select
              defaultValue={0}
              onChange={onToggleRecurrence}
              style={{ width: 120 }}
              value={Number(isRecurrence)}
              options={[
                {
                  label: 'Không lặp lại',
                  value: 0,
                },
                {
                  label: 'Lặp lại',
                  value: 1,
                },
              ]}
            />
          </Form.Item>

          {isRecurrence ? (
            <Row>
              <Space>
                {'mỗi'}
                <Form.Item
                  name={'repeat_interval'}
                  style={{ marginBottom: 0 }}
                  initialValue={1}>
                  <InputNumber min={1} max={31} style={{ width: 70 }} />
                </Form.Item>
              </Space>
              <FrequenceTypeSelect />
            </Row>
          ) : null}
        </Space>
      </Row>

      {isRecurrence && frequenceType === RRule.FrequenceType.WEEKLY ? (
        <DayOfWeekCheckboxGroup />
      ) : null}

      {isRecurrence ? (
        <>
          <Row>
            <Space>
              <span>Kết thúc sau</span>
              <Form.Item
                name={'repeat_count'}
                rules={[
                  {
                    required: true,
                    message: 'Số lần lặp lại không được để trống',
                  },
                ]}
                initialValue={1}>
                <InputNumber
                  style={{ marginLeft: 30, width: 170 }}
                  min={1}
                  max={occurenceCountMax[frequenceType]}
                  addonAfter={
                    <span style={{ textAlign: 'center', color: 'gray' }}>
                      {recurrenceText[frequenceType]}
                    </span>
                  }
                />
              </Form.Item>
            </Space>
          </Row>
        </>
      ) : null}
    </>
  )
}

CompanyWorkScheduleCreatePage.propTypes = {}

export default memo(
  inject(
    'commonStore',
    'loadingAnimationStore',
    'departmentStore',
    'workScheduleStore',
    'fileStore',
    'authenticationStore',
    'aclStore'
  )(observer(CompanyWorkScheduleCreatePage))
)
